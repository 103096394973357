import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'

function AtHome() {
  const testimonials = [
    {
      text:
        'Excellent service! Great idea to have contact-free delivery of projects to do at home! Thanks!',
      avatar:
        'https://images.unsplash.com/photo-1534818113099-dbe2b2e800ae?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Christine',
      title: 'Customer at Just Kil’n Time Ceramics Studio',
    },
    {
      text:
        'It was a great way for my kids and I to keep busy and learn how to do something new. Thank you! I love painting with you guys!',
      avatar:
        'https://images.unsplash.com/photo-1576558656222-ba66febe3dec?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Alex',
      title: 'Customer at Canvas and Cocktails',
    },
    {
      text:
        'We think it is a great plan to give us the virtual experience and to keep us safe. It worked well and can’t wait to come back again.',
      avatar:
        'https://images.unsplash.com/photo-1526080652727-5b77f74eacd2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Erica',
      title: 'Customer at Patio Gardens',
    },
    {
      text:
        'This was a great opportunity and handles very well!  Thank you so much for offering these!',
      avatar:
        'https://images.unsplash.com/photo-1551069613-1904dbdcda11?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Rosemary',
      title: 'Customer at Painted Grape',
    },
    {
      text:
        'So awesome! Thank you for delivering on time - my family will have so much fun with this art project!',
      avatar:
        'https://images.unsplash.com/photo-1559133292-1d8d5302bdda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Carey',
      title: 'Customer at  Pickled Picasso',
    },
  ]

  const [testimonial, setTestimonial] = useState(
    Math.floor(Math.random(0, 100)) % testimonials.length,
  )

  useEffect(() => {
    const next = (testimonial + 1) % testimonials.length

    const id = setTimeout(() => setTestimonial(next), 10000)
    return () => clearTimeout(id)
  }, [testimonial, testimonials.length])

  const steps = []

  steps.push(
    <>
      <div className="py-12 mb-8 overflow-hidden bg-gray-50 md:py-20 lg:py-24">
        <div className="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <svg
            className="absolute transform top-full right-full translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
          >
            <defs>
              <pattern
                id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
            />
          </svg>

          <div className="relative">
            <blockquote className="mt-8">
              <div className="max-w-3xl mx-auto text-2xl font-medium leading-9 text-center text-gray-900">
                <p>&ldquo;{testimonials[testimonial].text}&rdquo;</p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    <img
                      className="w-10 h-10 mx-auto rounded-full"
                      src={testimonials[testimonial].avatar}
                      alt=""
                    />
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium leading-6 text-gray-900">
                      {testimonials[testimonial].name}
                    </div>

                    <svg
                      className="hidden w-5 h-5 mx-1 text-teal-600 md:block"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div className="text-base font-medium leading-6 text-gray-500">
                      {testimonials[testimonial].title}
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </>,
  )

  steps.push(
    <>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Price & Schedule
            </h3>
            <p className="mt-1 text-sm leading-5 text-gray-500">
              This will help us give some suggestions when creating the
              listings.
            </p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form action="#" method="POST">
            <div className="shadow-lg sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white sm:p-6">
                <fieldset>
                  <legend className="text-base font-medium leading-6 text-gray-900">
                    What At-Home listings do you want to sell?
                  </legend>
                  <div className="mt-4">
                    <div className="flex items-start">
                      <div className="absolute flex items-center h-5">
                        <input
                          id="comments"
                          type="checkbox"
                          checked
                          className="w-4 h-4 text-teal-600 transition duration-150 ease-in-out form-checkbox"
                        />
                      </div>
                      <div className="text-sm leading-5 pl-7">
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-700"
                        >
                          At-Home Experiences
                        </label>
                        <p className="text-gray-500">
                          Customers sign up for a set date and time and attend
                          over video conference.
                        </p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex items-start">
                        <div className="absolute flex items-center h-5">
                          <input
                            id="candidates"
                            type="checkbox"
                            checked
                            className="w-4 h-4 text-teal-600 transition duration-150 ease-in-out form-checkbox"
                          />
                        </div>
                        <div className="text-sm leading-5 pl-7">
                          <label
                            htmlFor="candidates"
                            className="font-medium text-gray-700"
                          >
                            At-Home Kits
                          </label>
                          <p className="text-gray-500">
                            Customers purchase materials and they do the
                            activity on their own.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <section className="grid grid-cols-6 gap-6 mt-4">
                  <section className="col-span-6 lg:mt-4 sm:col-span-6 lg:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Preferred Frequency
                    </label>
                    <select
                      id="country"
                      className="block w-full px-3 py-0 py-2 mt-1 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm form-select focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    >
                      <option>Once a week</option>
                      <option selected>Few times per week</option>
                      <option>Every day</option>
                      <option>Multiple per day</option>
                    </select>
                    <p className="mt-1 text-sm text-gray-500">
                      How many times would you or your staff prefer to do an
                      At-Home Experience?
                    </p>
                  </section>

                  <section className="col-span-6 lg:mt-4 sm:col-span-6 lg:col-span-3">
                    <label
                      htmlFor="price"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Price per Attendee/Kit <i>(estimate)</i>
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span className="text-gray-500 sm:text-sm sm:leading-5">
                          $
                        </span>
                      </div>
                      <input
                        id="price"
                        className="block w-full pr-12 form-input pl-7 sm:text-sm sm:leading-5"
                        placeholder="0.00"
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <span className="text-gray-500 sm:text-sm sm:leading-5">
                          USD
                        </span>
                      </div>
                    </div>
                    <p className="mt-1 text-sm text-gray-500">
                      We recommend a price between 10 and 30? You can change the
                      prices later.
                    </p>
                  </section>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      City
                    </label>
                    <input
                      id="city"
                      className="block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      State / Province
                    </label>
                    <input
                      id="state"
                      className="block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    />
                  </div>
                </section>
                <p className="mt-1 text-sm text-gray-500">
                  Just to know where you are located. You are still able to sell
                  to different cities or states.
                </p>
              </div>
              <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-teal-600 border border-transparent rounded-md hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-teal active:bg-teal-700"
                  >
                    Save and Continue
                  </button>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>,
  )

  steps.push(
    <>
      <div className="hidden sm:block">
        <div className="py-10">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Delivery Options
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-500">
                These are the options you are going to give to your customers
                for their supplies. We will adjust the information we collect in
                the listing accordingly.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow-lg sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <fieldset className="mb-8">
                    <legend className="text-base font-medium leading-6 text-gray-900">
                      What delivery options do you want to provide?
                    </legend>
                    <div className="mt-4 option">
                      <div className="flex items-start">
                        <div className="absolute flex items-center h-5">
                          <input
                            id="comments"
                            type="checkbox"
                            checked
                            className="w-4 h-4 text-teal-600 transition duration-150 ease-in-out form-checkbox"
                          />
                        </div>
                        <div className="text-sm leading-5 pl-7">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Pick-Up
                          </label>
                          <p className="text-gray-500">
                            Customers can pick up the items from your location
                            for free.
                          </p>
                        </div>
                      </div>
                    </div>

                    <section className="px-6 py-6 my-3 -mx-6 bg-gray-50">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6">
                          <label
                            htmlFor="street_address"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Pickup address
                          </label>
                          <input
                            id="street_address"
                            className="block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label
                            htmlFor="city"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            City
                          </label>
                          <input
                            id="city"
                            className="block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="state"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            State / Province
                          </label>
                          <input
                            id="state"
                            className="block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="postal_code"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            ZIP / Postal
                          </label>
                          <input
                            id="postal_code"
                            className="block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                          />
                        </div>
                      </div>
                    </section>
                    <div className="mt-8 option">
                      <div className="flex items-start">
                        <div className="absolute flex items-center h-5">
                          <input
                            id="comments"
                            type="checkbox"
                            checked
                            className="w-4 h-4 text-teal-600 transition duration-150 ease-in-out form-checkbox"
                          />
                        </div>
                        <div className="text-sm leading-5 pl-7">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Deliver yourself
                          </label>
                          <p className="text-gray-500">
                            Deliver the items to your customers. This is the
                            easiest for local mcustomers.
                          </p>
                        </div>
                      </div>
                    </div>
                    <section className="px-6 py-6 my-3 -mx-6 bg-gray-50">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label
                            htmlFor="price"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Delivery Price
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <span className="text-gray-500 sm:text-sm sm:leading-5">
                                $
                              </span>
                            </div>
                            <input
                              id="price"
                              className="block w-full pr-12 form-input pl-7 sm:text-sm sm:leading-5"
                              placeholder="0.00"
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                              <span className="text-gray-500 sm:text-sm sm:leading-5">
                                USD
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="state"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Within how many miles?
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <input
                              id="price"
                              className="block w-full pr-12 form-input sm:text-sm sm:leading-5"
                              placeholder="20"
                              type="number"
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                              <span className="text-gray-500 sm:text-sm sm:leading-5">
                                miles
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <div className="mt-8 option">
                      <div className="flex items-start">
                        <div className="absolute flex items-center h-5">
                          <input
                            id="comments"
                            type="checkbox"
                            checked
                            className="w-4 h-4 text-teal-600 transition duration-150 ease-in-out form-checkbox"
                          />
                        </div>
                        <div className="text-sm leading-5 pl-7">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Delivery via Postmates
                          </label>
                          <p className="text-gray-500">
                            Customers can scheduled their same day delivery via
                            Postmates. This is the quickest delivery method.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-8 option">
                      <div className="flex items-start">
                        <div className="absolute flex items-center h-5">
                          <input
                            id="comments"
                            type="checkbox"
                            checked
                            className="w-4 h-4 text-teal-600 transition duration-150 ease-in-out form-checkbox"
                          />
                        </div>
                        <div className="text-sm leading-5 pl-7">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Shipping via USPS, UPS or Fedex
                          </label>
                          <p className="text-gray-500">
                            Customer can ship items with postal services. This
                            is slower, but cheaper and gives a bigger delivery
                            area.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-8 option">
                      <div className="flex items-start">
                        <div className="absolute flex items-center h-5">
                          <input
                            id="comments"
                            type="checkbox"
                            checked
                            className="w-4 h-4 text-teal-600 transition duration-150 ease-in-out form-checkbox"
                          />
                        </div>
                        <div className="text-sm leading-5 pl-7">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Order items ahead via Amazon
                          </label>
                          <p className="text-gray-500">
                            Let customers buy their supplies via Amazon. This is
                            the easiest way to set-up, but you have the least
                            amount of control.
                          </p>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                  <button className="px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-teal-600 border border-transparent rounded-md shadow-sm hover:bg-teal-500 focus:outline-none focus:shadow-outline-blue active:bg-teal-600">
                    Save and Continue
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        <div className="py-10">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>
    </>,
  )
  steps.push(
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Virtual Experience Setup
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-500">
                This is the method how you are connecting with your customers.
                We can do this fully automatically for you or you can choose
                your own.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow-lg sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <fieldset>
                    <legend className="text-base font-medium leading-6 text-gray-900">
                      How are you giving your experience?
                    </legend>
                    <p className="text-sm leading-5 text-gray-500">
                      Choose the tool you are going to use for your private
                      online experiences.
                    </p>
                    <div className="mt-4">
                      <div className="flex items-center">
                        <input
                          id="push_everything"
                          name="form-input push_notifications"
                          type="radio"
                          className="w-4 h-4 text-teal-600 transition duration-150 ease-in-out form-radio"
                          checked
                        />
                        <label htmlFor="push_everything" className="ml-3">
                          <span className="block text-sm font-medium leading-5 text-gray-700">
                            <strong>Google Hangouts:</strong> generated
                            automatically
                          </span>
                        </label>
                      </div>
                      <div className="flex items-center mt-4">
                        <input
                          id="push_email"
                          name="form-input push_notifications"
                          type="radio"
                          className="w-4 h-4 text-teal-600 transition duration-150 ease-in-out form-radio"
                        />
                        <label htmlFor="push_email" className="ml-3">
                          <span className="block text-sm font-medium leading-5 text-gray-700">
                            <strong>Zoom:</strong> generated automatically
                          </span>
                        </label>
                      </div>
                      <div className="flex items-center mt-4">
                        <input
                          id="push_email"
                          name="form-input push_notifications"
                          type="radio"
                          className="w-4 h-4 text-teal-600 transition duration-150 ease-in-out form-radio"
                        />
                        <label htmlFor="push_email" className="ml-3">
                          <span className="block text-sm font-medium leading-5 text-gray-700">
                            <strong>Jitsi Meet:</strong> generated automatically
                          </span>
                        </label>
                      </div>
                      <div className="flex items-center mt-4">
                        <input
                          id="push_nothing"
                          name="form-input push_notifications"
                          type="radio"
                          className="w-4 h-4 text-teal-600 transition duration-150 ease-in-out form-radio"
                        />
                        <label htmlFor="push_nothing" className="ml-3">
                          <span className="block text-sm font-medium leading-5 text-gray-700">
                            I will manually sent out instructions before the
                            event <i>(not recommended)</i>
                          </span>
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                  <button className="px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out bg-teal-600 border border-transparent rounded-md shadow-sm hover:bg-teal-500 focus:outline-none focus:shadow-outline-blue focus:bg-teal-500 active:bg-teal-600">
                    Save and Complete
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>,
  )

  return (
    <Layout restricted>
      <div className="py-10">
        <header>
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              Start selling Virtual Experiences & Kits
            </h1>
          </div>
        </header>
      </div>
      {steps}
    </Layout>
  )
}

export default AtHome
